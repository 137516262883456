.title {
  font-size: 35px;
  line-height: 1.3;
  color: #3A4248;
  font-weight: 700;
  max-width: 1320px;
  margin: 0 auto 48px;
}

@media(max-width: 1360px) {
  .title {
    padding: 0 24px;
  }
}

@media(max-width: 800px) {
  .title {
    margin-bottom: 32px;
  }
}