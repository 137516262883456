.our-partners {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px;
  padding: 80px 10px 140px 90px;
  align-items: baseline;
}

@media(max-width: 1320px) {
  .our-partners {
    padding: 75px 40px 118px 70px;
  }
}

@media(max-width: 1180px) {
  .our-partners {
    padding: 70px;
    text-align: center;
  }
}

@media(max-width: 830px) {
  .our-partners {
    padding: 50px;
    grid-row-gap: 50px;
    grid-column-gap: 46px;
  }
}

@media(max-width: 700px) {
  .our-partners {
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width: 530px) {
  .our-partners {
    grid-template-columns: 1fr;
    padding: 24px;
  }
}