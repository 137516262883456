.contacts {
  background: url('/assets/img/contacts.png') no-repeat center top;
  padding-top: 68px;
  padding-bottom: 86px;

  &__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 200px;
    height: 200px;
    background: url(/assets/img/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 84px;
  }

  &__links {
    font-size: 17px;
    font-weight: 300;
    flex: 1;
    margin-right: 20px;

    a {
      display: inline-block;
      position: relative;
      padding-left: 18px;

      &::before {
        content: '';
        display: block;
        border-top: 2px solid #3A4248;
        border-left: 2px solid #3A4248;
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% - 2px)) rotate(-135deg);
      }
    }

    & > * {
      margin-bottom: 24px;
    }
  }

  &__phones {
    & > * {
      display: block;

      &:nth-child(n+2) {
        margin-top: 52px;
      }
    }
  }

  &__address {
    flex: 2;

    & > * {
      &:nth-child(n+2) {
        margin-top: 60px;
      }
    }
  }
}

.address {
  &__title {
    font-size: 18px;
  }

  &__desc {
    font-size: 14px;
    font-weight: 300;
    color: #7F7F7F;
    margin-top: 15px;
  }
}

@media(max-width: 1360px) {
  .contacts__wrapper {
    padding: 0 24px;
  }

  .contacts {
    background-position: top right;
  }
}

@media(max-width: 1130px) {
  .contacts__logo {
    width: 160px;
    height: 160px;
    margin-right: 40px;
  }
}

@media(max-width: 1080px) {
  .contacts__logo {
    width: 120px;
    height: 120px;
    margin-right: 24px;
  }
}

@media(max-width: 990px) {
  .contacts__logo {
    display: none;
  }
}

@media(max-width: 836px) {
  .contacts {
    background: transparent;
  }
}

@media(max-width: 820px) {
  .contacts__links {
    display: none;
  }
}

@media(max-width: 610px) {
  .contacts__wrapper {
    display: block;
  }

  .contacts__address > *:nth-child(n+2) {
    margin-top: 30px;
  }

  .contacts__phones {
    margin-top: 50px;

    .phone__label > * {
      display: inline-block;
    }
  }

  .contacts__phones > *:nth-child(n+2) {
    margin-top: 38px;
  }
}

.contacts_sec-page {
  background: transparent;
}