.phone {
  font-family: 'Roboto';
  color: #3A4248;

  &__code {
    font-weight: 100;
    font-size: 18px;
    margin-right: 6px;
  }

  &__number {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: -1px;
  }

  &__label {
    display: block;
    margin-top: 3px;

    & > * {
      margin-left: 86px;
      font-weight: 100;
      padding: 5px 16px;
      position: relative;
      color: #FFF;
      font-size: 14px;
      line-height: .8;
      background: #AD253C;
      overflow: hidden;
      display: block;

      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 24px;
        background: #FFF;
        position: absolute;
        top: -2px;
        right: -7px;
        transform: rotate(10deg);
      }
    }
  }
}

@media(max-width: 1086px) {
  .phone {
    &__number {
      font-size: 32px;
    }
  }
}