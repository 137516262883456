.footer {
  background: #AD253C url(/assets/img/tab.png) repeat center;
  padding: 17px 0 15px;
  color: #FFF;
  font-size: 14px;

  &__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }

  &__copy {
    text-align: center;
  }

  &__socials {
    text-align: center;
  }
}

@media(max-width: 1360px) {
  .footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media(max-width: 510px) {
  .footer {
    &__wrapper {
      grid-template-columns: 1fr;
    }

    &__dev {
      display: none;
    }
  }
}