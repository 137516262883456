@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&subset=cyrillic-ext");
@import url("https://fonts.googleapis.com/css?family=Raleway:600");
@font-face {
  font-family: 'Madelyn-Regular';
  src: local("Madelyn-Regular"), url("/assets/fonts/medlun.woff") format("woff"); }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

body {
  line-height: 1.2;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #3A4248; }

button, input, select {
  outline: none; }

a {
  cursor: pointer;
  color: inherit;
  outline: none;
  text-decoration: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: none; }

textarea:focus {
  outline: 0; }

.s-background {
  background: url("/assets/img/s-background.png") no-repeat center top; }

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none; }

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: .4s; }
  .menu_second:not(.menu_open) {
    background: #AD253C url("/assets/img/menu.svg") repeat-x bottom -3px center;
    color: #FFF; }
    .menu_second:not(.menu_open) .menu__wrapper {
      margin-top: 10px;
      margin-bottom: 5px; }
      .menu_second:not(.menu_open) .menu__wrapper ul {
        color: #3A4248; }
      .menu_second:not(.menu_open) .menu__wrapper > *:not(.menu-search) ul + a::after {
        border-bottom-color: #FFF;
        border-left-color: #FFF; }
    .menu_second:not(.menu_open) .menu-search__close::before, .menu_second:not(.menu_open) .menu-search__close::after {
      background: #FFF; }
    .menu_second:not(.menu_open) .menu-search input {
      color: #FFF;
      background: url(/assets/img/search_white.svg) no-repeat left center;
      background-size: 20px; }
  .menu__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 18px;
    height: 18px;
    z-index: 2;
    cursor: pointer;
    display: none; }
    .menu__close::before, .menu__close::after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: #3A4248; }
    .menu__close::after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .menu_white {
    background: #FFF; }
  .menu__wrapper {
    max-width: 1320px;
    margin: 18px auto 0;
    font-size: 17px;
    font-weight: 300;
    position: relative; }
    .menu__wrapper > *:not(.menu-search) {
      display: inline-block;
      cursor: pointer;
      margin-right: 40px;
      position: relative; }
      .menu__wrapper > *:not(.menu-search):hover ul {
        display: block; }
      .menu__wrapper > *:not(.menu-search):first-child {
        margin-left: -10px; }
      .menu__wrapper > *:not(.menu-search) > a {
        padding: 10px;
        display: inline-block;
        position: relative;
        padding-bottom: 18px; }
      .menu__wrapper > *:not(.menu-search) ul {
        display: none;
        position: absolute;
        bottom: 0;
        left: 10px;
        transform: translate(0, 100%);
        background: #FFF;
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.08);
        white-space: nowrap; }
        .menu__wrapper > *:not(.menu-search) ul > li a {
          padding: 15px 22px;
          position: relative;
          display: block; }
          .menu__wrapper > *:not(.menu-search) ul > li a::before {
            content: '';
            display: block;
            width: 4px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #AD253C;
            opacity: 0;
            transition: .4s; }
          .menu__wrapper > *:not(.menu-search) ul > li a:hover::before {
            opacity: 1; }
        .menu__wrapper > *:not(.menu-search) ul + a::after {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          border-bottom: 1px solid #3A4248;
          border-left: 1px solid #3A4248;
          position: absolute;
          top: 14px;
          right: -10px;
          transform: rotate(-45deg); }

.menu-search {
  position: absolute;
  top: 2px;
  right: 0;
  width: 0;
  transition: .4s; }
  .menu-search__close {
    position: absolute;
    top: 10px;
    right: 0;
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    transition: .4s; }
    .menu-search__close::before, .menu-search__close::after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: #3A4248; }
    .menu-search__close::after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .menu-search input {
    color: #3A4248;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    padding-left: 20px;
    background: url("/assets/img/search.svg") no-repeat left center;
    height: 30px;
    border: 0;
    font-size: 17px;
    cursor: pointer;
    transition: .4s;
    width: 100%;
    background-size: 20px;
    padding-top: 5px;
    position: relative;
    z-index: 2;
    transform: translateX(-100%); }
    .menu-search input::placeholder {
      color: inherit; }

.menu_search .menu__wrapper > *:not(.menu-search) {
  opacity: 0; }

.menu_search .menu-search {
  width: 100%; }
  .menu_search .menu-search input {
    padding-left: 48px;
    cursor: auto;
    transform: translateX(0); }
  .menu_search .menu-search__close {
    z-index: 2;
    opacity: 1; }

@media (max-width: 1360px) {
  .menu__wrapper {
    margin-left: 24px;
    margin-right: 24px; } }

@media (max-width: 1300px) {
  .menu__wrapper {
    margin-top: 13px; }
  .menu__wrapper > *:not(.menu-search) {
    margin-right: 24px; } }

@media (max-width: 1130px) {
  .menu-search {
    display: none; }
  .menu__wrapper {
    font-size: 16px; }
  .menu__wrapper > *:not(.menu-search) {
    margin-right: 22px; } }

@media (max-width: 1060px) {
  .menu__wrapper {
    background: #FFF;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding: 24px;
    padding-bottom: 12px;
    box-sizing: border-box;
    transform: translate(0, -100%);
    transition: .4s; }
  .menu_open .menu__wrapper {
    transform: translate(0, 0); }
  .menu__wrapper > *:not(.menu-search) ul {
    display: none !important; }
  .menu__wrapper > *:not(.menu-search) a::after {
    display: none !important; }
  .menu__wrapper > *:not(.menu-search):first-child {
    margin-left: 0; } }

@media (max-width: 900px) {
  .menu__wrapper {
    grid-template-columns: 1fr 1fr 1fr; } }

@media (max-width: 600px) {
  .menu__wrapper {
    grid-template-columns: 1fr 1fr; } }

.mobile-menu {
  position: fixed;
  top: 24px;
  right: 24px;
  width: 42px;
  height: 42px;
  background: #30415d;
  z-index: 2;
  cursor: pointer;
  display: none; }
  .mobile-menu::before {
    content: '';
    display: block;
    width: 20px;
    height: 16px;
    position: absolute;
    border-top: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; }
  .mobile-menu::after {
    content: '';
    display: block;
    height: 2px;
    background: #FFF;
    width: 20px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); }
  @media (max-width: 1060px) {
    .mobile-menu {
      display: block; } }

.menu_open .menu__close {
  display: block; }

.header {
  width: 100%;
  position: relative;
  min-height: 880px; }
  .header__desc {
    font-family: 'Madelyn-Regular';
    font-size: 55px;
    margin-top: 84px;
    color: #AAB4C0; }
  .header__slider {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(1320px - 740px + ((100% - 1320px)/2));
    overflow: hidden; }
  .header__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 140px; }
  .header__text-slider {
    margin-top: 88px;
    position: relative; }
    .header__text-slider::before {
      content: '';
      display: block;
      width: 169px;
      height: 89px;
      background: url("/assets/img/fish.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      top: -78px;
      right: 49%;
      z-index: 1;
      pointer-events: none; }
  .header__footer {
    margin-top: 60px;
    padding-right: 70px; }
  .header__second {
    display: flex;
    justify-content: space-between; }
    .header__second .phone .phone__label > * {
      display: inline-block; }

.header-slider__block {
  position: relative;
  height: 880px !important;
  overflow: hidden;
  outline: none; }
  .header-slider__block img {
    position: absolute;
    top: 50%;
    right: 0;
    height: 100%;
    transform: translateY(-50%); }

.header-logo {
  display: flex;
  align-items: center; }
  .header-logo__pic {
    width: 200px;
    height: 200px;
    background: url("/assets/img/logo.svg");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    margin-right: 60px; }
  .header-logo__text {
    font-size: 45px;
    font-weight: 700;
    color: #3A4248;
    line-height: 1.3;
    padding-top: 26px;
    text-transform: capitalize;
    position: relative; }
    .header-logo__text:before {
      content: '';
      display: block;
      width: 111px;
      height: 10px;
      background: url("/assets/img/logo-wave.svg") no-repeat left;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0; }

.text-slider {
  background: rgba(48, 65, 93, 0.81);
  color: #FFF; }
  .text-slider__block {
    outline: none;
    position: relative;
    padding-top: 50px;
    padding-left: 45px;
    padding-bottom: 73px;
    padding-right: 200px; }
  .text-slider__text {
    line-height: 1.7;
    font-size: 20px;
    position: relative;
    z-index: 1; }
  .text-slider__title {
    position: absolute;
    bottom: 0;
    right: 46px;
    font-size: 108px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.1);
    font-weight: 700;
    line-height: .7;
    letter-spacing: 8px; }
  .text-slider .slick-arrow {
    position: absolute;
    bottom: -167px;
    right: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    background: #30415D;
    cursor: pointer;
    transition: .4s; }
    .text-slider .slick-arrow:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-left: 2px solid #FFF;
      border-top: 2px solid #FFF;
      position: absolute;
      top: 50%;
      left: calc(50% - 2px);
      transform: translate(-50%, -50%) rotate(135deg); }
    .text-slider .slick-arrow.slick-prev {
      right: 70px; }
      .text-slider .slick-arrow.slick-prev:before {
        left: calc(50% + 2px);
        transform: translate(-50%, -50%) rotate(-45deg); }
    .text-slider .slick-arrow:hover {
      opacity: .81; }

.header-footer {
  display: flex;
  justify-content: space-between;
  width: calc(1320px - 580px + ((100% - 1320px)/2));
  box-sizing: border-box; }

@media (max-width: 1360px) {
  .header-logo {
    padding: 0 24px; }
  .header-footer {
    padding-left: 24px; }
  .text-slider .slick-arrow.slick-next {
    right: 24px; }
  .text-slider .slick-arrow.slick-prev {
    right: 94px; } }

@media (max-width: 1240px) {
  .header-logo__text {
    font-size: 35px; }
  .text-slider__title {
    font-size: 80px; } }

@media (max-width: 1086px) {
  .header {
    min-height: auto; }
  .header-logo__text {
    font-size: 30px; }
  .header-logo__pic {
    width: 180px;
    height: 180px; }
  .text-slider__text {
    font-size: 18px;
    line-height: 1.4; }
  .text-slider .slick-arrow {
    bottom: inherit;
    top: -80px; }
  .header-footer {
    padding-right: 24px;
    width: 100%; }
  .header-slider__block {
    height: 604px !important; } }

@media (max-width: 924px) {
  .header-logo__pic {
    margin-right: 40px; } }

@media (max-width: 884px) {
  .header-logo__text {
    font-size: 28px; } }

@media (max-width: 840px) {
  .header__slider {
    display: none; } }

@media (max-width: 780px) {
  .text-slider__block {
    padding: 24px;
    padding-bottom: 46px; }
  .text-slider__title {
    right: 24px;
    font-size: 44px; } }

@media (max-width: 530px) {
  .header-footer {
    display: block; }
    .header-footer__phone {
      margin-top: 32px;
      text-align: center; }
      .header-footer__phone .phone {
        display: inline-block; }
    .header-footer__products .button {
      width: calc(100% - 30px); }
      .header-footer__products .button > * {
        width: 100%; } }

@media (max-width: 500px) {
  .header-logo__text {
    font-size: 26px; }
  .text-slider__text {
    font-size: 16px; }
  .text-slider__title {
    font-size: 36px; }
  .text-slider .slick-arrow {
    width: 42px;
    height: 42px;
    top: -60px; }
    .text-slider .slick-arrow.slick-prev {
      right: 78px; }
    .text-slider .slick-arrow::before {
      width: 10px;
      height: 10px; } }

@media (max-width: 430px) {
  .header-logo {
    display: block;
    padding-bottom: 30px; }
    .header-logo__pic {
      width: 100%;
      height: 200px;
      margin-right: 0;
      margin-bottom: 32px; } }

@media (max-width: 1060px) {
  .header__wrapper {
    padding-top: 45px; } }

@media (max-width: 640px) {
  .header__wrapper {
    padding-top: 30px; } }

.header-second__right {
  text-align: right; }

.header_second {
  min-height: auto; }
  .header_second .header__text-slider {
    margin-top: 30px; }
    .header_second .header__text-slider::before {
      right: 42%; }

@media (max-width: 1360px) {
  .header-second__right {
    padding-right: 24px; } }

@media (max-width: 1060px) {
  .header__desc {
    margin-top: 40px; }
  .header-second__right {
    padding-top: 40px; } }

@media (max-width: 880px) {
  .header__desc {
    font-size: 40px; } }

@media (max-width: 784px) {
  .header__desc {
    display: none; } }

@media (max-width: 760px) {
  .header__second {
    display: block; }
  .header-second__right {
    text-align: center;
    padding: 30px 24px 0; }
  .header_second .header__text-slider {
    display: none; } }

.button {
  position: relative;
  display: inline-block;
  line-height: 0;
  transition: .4s;
  cursor: pointer; }
  .button::after {
    content: '';
    display: block;
    transition: .4s; }
  .button > * {
    background-color: #AD253C;
    color: #FFF;
    line-height: 1.2;
    padding: 18px 40px 16px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 500;
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    text-align: center; }
    .button > *::after {
      transition: .4s; }

.button_fish::after {
  content: '';
  display: block;
  background: url("/assets/img/button/fish.svg") no-repeat center;
  width: 42px;
  height: 19px;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: -22px;
  transform: translateY(-50%); }

.button_fish > *::after {
  content: '';
  display: block;
  background: url("/assets/img/button/fish_white.svg") no-repeat center;
  width: 42px;
  height: 19px;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: -22px;
  transform: translateY(-50%); }

.button_fish:hover::after {
  right: -32px; }

.button_fish:hover > *::after {
  right: -32px; }

.button_form {
  width: calc(100% - 22px); }
  .button_form > span {
    background: #30415D;
    width: 100%; }
  .button_form::after {
    content: '';
    display: block;
    background: url("/assets/img/button/arrow.svg") no-repeat center;
    width: 42px;
    height: 19px;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: -22px;
    transform: translateY(-50%); }
  .button_form > *::after {
    content: '';
    display: block;
    background: url("/assets/img/button/arrow_white.svg") no-repeat center;
    width: 42px;
    height: 19px;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: -22px;
    transform: translateY(-50%); }
  .button_form:hover::after {
    right: -32px; }
  .button_form:hover > *::after {
    right: -32px; }

.button_news::after {
  content: '';
  display: block;
  background: url("/assets/img/button/news.svg") no-repeat center;
  width: 25px;
  height: 20px;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%); }

.button_news > *::after {
  content: '';
  display: block;
  background: url("/assets/img/button/news_white.svg") no-repeat center;
  width: 25px;
  height: 20px;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%); }

.button_news:hover::after {
  right: -20px; }

.button_news:hover > *::after {
  right: -20px; }

@media (max-width: 1086px) {
  .button > * {
    font-size: 14px; } }

.phone {
  font-family: 'Roboto';
  color: #3A4248; }
  .phone__code {
    font-weight: 100;
    font-size: 18px;
    margin-right: 6px; }
  .phone__number {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: -1px; }
  .phone__label {
    display: block;
    margin-top: 3px; }
    .phone__label > * {
      margin-left: 86px;
      font-weight: 100;
      padding: 5px 16px;
      position: relative;
      color: #FFF;
      font-size: 14px;
      line-height: .8;
      background: #AD253C;
      overflow: hidden;
      display: block; }
      .phone__label > *::after {
        content: '';
        display: block;
        width: 10px;
        height: 24px;
        background: #FFF;
        position: absolute;
        top: -2px;
        right: -7px;
        transform: rotate(10deg); }

@media (max-width: 1086px) {
  .phone__number {
    font-size: 32px; } }

.title {
  font-size: 35px;
  line-height: 1.3;
  color: #3A4248;
  font-weight: 700;
  max-width: 1320px;
  margin: 0 auto 48px; }

@media (max-width: 1360px) {
  .title {
    padding: 0 24px; } }

@media (max-width: 800px) {
  .title {
    margin-bottom: 32px; } }

.main-page {
  padding-top: 94px;
  position: relative; }
  .main-page::before {
    content: '';
    display: block;
    width: 1270px;
    height: 1270px;
    position: absolute;
    top: -237px;
    left: 0;
    transform: rotate(-135deg) translateY(calc(-50% - 263px));
    background: #9EA6B2;
    opacity: .15;
    z-index: 1;
    pointer-events: none; }

.main-page-news {
  padding-right: 164px; }
  .main-page-news__all {
    margin-top: 50px; }
  .main-page-news__link {
    color: #AD253C;
    font-size: 14px;
    font-weight: 300;
    margin-top: 26px;
    display: inline-block;
    position: relative; }
    .main-page-news__link::after {
      content: '';
      display: block;
      width: 23px;
      height: 10px;
      background: url("/assets/img/news.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: -40px;
      transform: translateY(-50%);
      transition: .4s; }
    .main-page-news__link:hover::after {
      right: -50px; }
  .main-page-news__title {
    font-size: 20px; }
  .main-page-news__description {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 24px;
    color: #7F7F7F; }
  .main-page-news__content > *:nth-child(n+2) {
    margin-top: 65px; }

@media (max-width: 1360px) {
  .main-page::before {
    width: 1100px;
    height: 1100px; } }

@media (max-width: 1190px) {
  .main-page::before {
    display: none; } }

@media (max-width: 1020px) {
  .main-page {
    padding-top: 60px; } }

@media (max-width: 1360px) {
  .main-page-f .title {
    padding: 0; }
  .main-page-news {
    padding: 0 42px 0 24px; } }

.all-production {
  max-width: 1320px;
  margin: 0 auto 74px;
  color: #3A4248; }
  .all-production__tabs {
    font-size: 0;
    padding-right: 122px;
    margin: -16px 0 0 -25px; }
    .all-production__tabs > * {
      display: inline-block;
      font-size: 16px;
      padding: 12px 42px;
      font-weight: 300;
      border: 0.5px solid rgba(58, 66, 72, 0.5);
      cursor: pointer;
      margin-left: 25px;
      transition: .4s;
      margin-top: 16px; }
      .all-production__tabs > *:hover {
        background: #30415D;
        color: #FFF; }
      .all-production__tabs > *.active {
        background: #30415D url("/assets/img/tab.png") repeat center;
        color: #FFF; }
  .all-production__content {
    margin-top: 90px; }
    .all-production__content .slick-slide {
      outline: none; }
    .all-production__content .slick-prev, .all-production__content .slick-next {
      content: '';
      display: block;
      position: absolute;
      top: -120px;
      right: 20px;
      font-size: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      border: 0;
      cursor: pointer; }
      .all-production__content .slick-prev::before, .all-production__content .slick-next::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-right: 2px solid #30415D;
        border-bottom: 2px solid #30415D;
        transform: rotate(-45deg); }
    .all-production__content .slick-prev {
      right: 65px; }
      .all-production__content .slick-prev::before {
        border-right: 0;
        border-bottom: 0;
        border-left: 2px solid #30415D;
        border-top: 2px solid #30415D; }

@media (max-width: 1320px) {
  .all-production__tabs {
    padding-left: 24px; } }

@media (max-width: 1020px) {
  .all-production__tabs {
    padding-right: 24px; }
  .all-production__content {
    margin-top: 130px; }
    .all-production__content .slick-next, .all-production__content .slick-prev {
      right: 72px;
      top: -80px; }
    .all-production__content .slick-next {
      right: 36px; } }

.product {
  color: #3A4248; }
  .product_new .product__pic::after {
    content: 'Новинка';
    display: inline-block;
    padding: 8px 8px 7px 40px;
    background: #AD253C;
    color: #FFF;
    text-transform: uppercase;
    font-size: 13px;
    line-height: .8;
    letter-spacing: 1px;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    right: 0; }
  .product__pic {
    max-width: 215px;
    margin: 0 auto;
    position: relative; }
    .product__pic img {
      width: 100%; }
  .product__info {
    max-width: 215px;
    margin: 16px auto 0;
    text-align: center;
    font-size: 14px; }
  .product__name {
    line-height: 1.2;
    height: 32px;
    overflow: hidden; }
  .product__key {
    font-weight: 300;
    margin-top: 10px; }

.about-us {
  width: 100%;
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.5);
  background: url("/assets/img/about.png") no-repeat center;
  background-size: cover;
  color: #FFF;
  position: relative; }
  .about-us > * {
    position: relative;
    z-index: 1; }
  .about-us__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; }
  .about-us__text {
    padding-top: 100px;
    padding-bottom: 74px;
    max-width: 730px; }
  .about-us__title {
    font-size: 35px;
    font-weight: 500;
    line-height: 1.2;
    position: relative; }
    .about-us__title::before {
      content: '';
      display: block;
      width: 85px;
      height: 26px;
      background: url("/assets/img/about.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      top: -48px;
      left: 0; }
  .about-us__desc {
    font-size: 20px;
    line-height: 1.5;
    padding-left: 30px;
    opacity: .8;
    margin-top: 34px; }
  .about-us__num {
    font-size: 108px;
    font-family: 'Raleway';
    font-weight: 600; }
    .about-us__num span {
      font-family: 'Roboto';
      font-size: 50px;
      font-weight: 400; }
  .about-us__int {
    padding-top: 36px;
    padding-right: 60px; }
  .about-us__regions {
    display: flex; }
    .about-us__regions .about-us__num-desc {
      background: url("/assets/img/about-us_reg.svg") no-repeat center top 9px;
      margin-top: 31px;
      margin-left: 40px;
      padding-left: 6px;
      padding-right: 24px;
      line-height: 1.4; }
  .about-us__products {
    display: flex;
    align-items: flex-end;
    margin-top: 44px;
    position: relative; }
    .about-us__products::before {
      content: '';
      display: block;
      position: absolute;
      top: -13px;
      left: 72px;
      width: 111px;
      height: 10px;
      background: url("/assets/img/about-us.svg") no-repeat center;
      background-size: contain; }
    .about-us__products .about-us__num-desc {
      background: url("/assets/img/about-us_prod.svg") no-repeat center top 9px;
      margin-right: 40px;
      padding-right: 6px;
      line-height: 1.4;
      text-align: right; }
  .about-us__num-desc {
    font-size: 16px;
    font-weight: 300; }

@media (max-width: 1360px) {
  .about-us__wrapper {
    padding: 0 24px;
    box-sizing: border-box; } }

@media (max-width: 1250px) {
  .about-us__int {
    padding-right: 0; } }

@media (max-width: 1190px) {
  .about-us__title {
    font-size: 28px; }
  .about-us__text {
    max-width: 585px; }
  .about-us__desc {
    font-size: 18px;
    opacity: 1; } }

@media (max-width: 1040px) {
  .about-us {
    position: relative; }
    .about-us::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url(/assets/img/advanages.png) repeat center;
      opacity: .8;
      position: absolute;
      top: 0;
      left: 0; }
  .about-us__wrapper {
    display: block;
    position: relative;
    z-index: 1; }
  .about-us__text {
    max-width: inherit;
    padding-top: 78px;
    padding-bottom: 0; }
  .about-us__int {
    display: inline-block; }
  .about-us__wrapper {
    text-align: center;
    padding-bottom: 40px; }
  .about-us__desc {
    padding-left: 0; }
  .about-us__regions .about-us__num-desc, .about-us__text {
    text-align: left; } }

@media (max-width: 440px) {
  .about-us__num {
    font-size: 88px; }
    .about-us__num span {
      font-size: 34px; } }

@media (max-width: 350px) {
  .about-us__num {
    font-size: 84px; }
    .about-us__num span {
      font-size: 32px; }
  .about-us__regions .about-us__num-desc {
    margin-left: 18px; }
  .about-us__products .about-us__num-desc {
    margin-right: 18px; } }

@media (max-width: 320px) {
  .about-us__num {
    font-size: 80px; }
    .about-us__num span {
      font-size: 28px; }
  .about-us__regions .about-us__num-desc {
    margin-left: 12px; }
  .about-us__products .about-us__num-desc {
    margin-right: 12px; } }

@media (max-width: 320px) {
  .about-us__num {
    font-size: 60px; } }

.advantages {
  width: 100%;
  background: url("/assets/img/advanages.png") repeat center;
  color: #FFF;
  font-size: 16px;
  padding-bottom: 140px;
  position: relative; }
  .advantages > * {
    position: relative;
    z-index: 1; }
  .advantages::after {
    content: '';
    display: block;
    width: 100%;
    height: 35px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("/assets/img/advanages.svg") repeat-x bottom center;
    background-size: auto 100%; }
  .advantages__wrapper {
    max-width: 1320px;
    margin: 0 auto; }
  .advantages__title {
    text-transform: uppercase;
    color: #FFF;
    opacity: .2;
    font-weight: 500;
    line-height: .77;
    font-size: 58px;
    letter-spacing: 3px;
    margin-bottom: 56px; }
  .advantages__list {
    display: flex;
    justify-content: space-between;
    max-width: 1080px;
    margin: 0 auto; }

.advantage {
  text-align: center; }
  .advantage__pic {
    height: 107px;
    margin-bottom: 26px;
    position: relative; }
    .advantage__pic::after {
      content: '';
      width: 54px;
      height: 1px;
      background: #FFF;
      position: absolute;
      bottom: -26px;
      left: 50%;
      transform: translateX(-50%); }
  .advantage__desc {
    line-height: 1.5;
    font-weight: 100;
    padding-top: 17px;
    display: block; }
    .advantage__desc span {
      font-weight: 400; }

@media (max-width: 1360px) {
  .advantages__title {
    padding: 0 24px; } }

@media (max-width: 1180px) {
  .advantages {
    padding-bottom: 80px; }
  .advantage {
    padding: 30px 24px; } }

@media (max-width: 870px) {
  .advantages {
    padding-top: 64px; }
  .advantages__title {
    font-size: 35px;
    line-height: 1.3;
    opacity: 1;
    text-transform: none;
    letter-spacing: inherit;
    margin-bottom: 42px; } }

@media (max-width: 980px) {
  .advantages__list {
    flex-wrap: wrap;
    justify-content: center; }
    .advantages__list > * {
      width: 33%;
      box-sizing: border-box; } }

@media (max-width: 670px) {
  .advantages__list > * {
    width: 50%; } }

@media (max-width: 400px) {
  .advantages__list > * {
    width: 100%; }
  .advantage__pic {
    height: auto; } }

.form {
  padding-top: 86px;
  padding-bottom: 86px; }
  .form > * {
    position: relative;
    z-index: 1; }
  .form__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    box-sizing: border-box;
    padding-left: 70px;
    grid-row-gap: 56px;
    grid-column-gap: 116px; }
  .form__desc {
    font-size: 20px;
    line-height: 1.3;
    position: relative; }
    .form__desc::before {
      content: '';
      display: block;
      width: 46px;
      height: 30px;
      background: url("/assets/img/quote.svg") no-repeat center;
      background-size: contain;
      position: absolute;
      top: 0;
      left: -70px; }
  .form__input {
    position: relative; }
    .form__input::after {
      content: '';
      display: block;
      width: 50px;
      height: 2px;
      background: #30415D; }
    .form__input input, .form__input select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 18px;
      color: inherit;
      font-family: inherit;
      width: 100%;
      border: 0;
      background: transparent;
      border-bottom: 1px solid rgba(58, 66, 72, 0.5);
      padding-bottom: 12px; }
      .form__input input::placeholder, .form__input select::placeholder {
        color: #30415D; }
    .form__input select {
      background: url("/assets/img/select.svg") no-repeat center right 10px;
      background-size: 11px auto;
      cursor: pointer;
      padding-right: 22px; }
  .form__title {
    display: block;
    font-weight: 300;
    font-size: 14px;
    color: #7F7F7F;
    margin-bottom: 22px; }
  .form__button {
    padding-top: 24px; }
  .form__privacy {
    font-size: 14px;
    font-weight: 300;
    margin-top: 22px; }
    .form__privacy label {
      cursor: pointer;
      line-height: 1.4;
      display: flex; }
    .form__privacy input {
      width: 13px;
      height: 13px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid rgba(58, 66, 72, 0.5);
      display: inline-block;
      line-height: 0;
      vertical-align: -5px;
      border-radius: 50%;
      margin-right: 10px;
      margin-left: 0;
      cursor: pointer;
      margin-top: 2px; }
      .form__privacy input:checked {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30415d+37,ffffff+38,ffffff+100 */
        background: #30415d;
        /* Old browsers */
        background: -moz-radial-gradient(center, ellipse cover, #30415d 37%, white 38%, white 100%);
        /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, #30415d 37%, white 38%, white 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, #30415d 37%, white 38%, white 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30415d', endColorstr='#ffffff',GradientType=1 );
        /* IE6-9 fallback on horizontal gradient */ }
    .form__privacy a {
      border-bottom: 1px dashed rgba(58, 66, 72, 0.5);
      transition: .4s; }
      .form__privacy a:hover {
        border-color: transparent; }

@media (max-width: 1360px) {
  .form__wrapper {
    padding-right: 24px;
    grid-column-gap: 60px; } }

@media (max-width: 1170px) {
  .form__wrapper {
    grid-column-gap: 40px; } }

@media (max-width: 1120px) {
  .form__wrapper {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px; } }

@media (max-width: 880px) {
  .form__wrapper {
    grid-column-gap: 24px; } }

@media (max-width: 750px) {
  .form__desc {
    padding-left: 40px;
    font-size: 16px; }
    .form__desc:before {
      width: 23px;
      height: 15px;
      top: 6px;
      left: 0px; }
  .form__wrapper {
    padding-left: 24px;
    grid-template-columns: 1fr;
    grid-row-gap: 46px; } }

.form__input.error .form__title {
  color: #AD253C; }

.form__privacy .form__privacy-error {
  display: none;
  margin-top: 20px;
  border: 2px solid #AD253C;
  color: #AD253C;
  padding: 13px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.2; }

.form__privacy.error .form__privacy-error {
  display: block; }

.hide-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: none;
  overflow: auto; }

.hide-window .hide-window__hide-window-wrapper.hide-window-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 44px;
  outline: 10px solid rgba(255, 255, 255, 0.7);
  background: #FFF;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  text-align: center; }
  .hide-window .hide-window__hide-window-wrapper.hide-window-wrapper .title {
    margin-bottom: 20px; }

.hide-window .hide-window__hide-window-wrapper.hide-window-wrapper::after {
  content: '';
  display: block;
  width: 100%;
  clear: both; }

.hide-window .hide-window__hide-window-wrapper.hide-window-wrapper .hide-window-wrapper__close {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAN5JREFUeNqslYsNwyAMBRmhozBCR/EoHSEjeISMwihs4CZpaRPzsV+pJRQpgrsoPEMQkaDGbRv8fgbn2OeTft8CJ3lVcgpYvkU9+BksTgFLXaThLbAlYOkXFfgI3BOw2EX7xLiN7JicTpvtqaV8iVeQnWDWGxqBxSa4FcVZAY9yPiNgq4l+FXCLM+q8BwCPCJzAX5Jbgn+AuwINXiZjeBEEsKUhAQpegU6OJXZI3LwxXb2byGAfHIecJyUMNtrneLZiyMYVpwWXc3+UcwusBdWN1VtwB27+IqiuwqcAAwCxKFl9fhw5jwAAAABJRU5ErkJggg==") no-repeat center;
  position: absolute;
  top: -10px;
  right: -48px;
  width: 23px;
  height: 23px;
  opacity: .7;
  transition: .4s;
  cursor: pointer; }

.main-page-f {
  display: flex; }
  .main-page-f__left {
    text-align: right;
    width: calc(1320px - 776px + ((100% - 1320px)/2)); }
    .main-page-f__left > * {
      display: inline-block;
      text-align: left;
      width: 544px;
      box-sizing: border-box;
      padding-top: 20px; }
  .main-page-f__right {
    background: url("/assets/img/partners.png") no-repeat center left;
    background-size: cover;
    flex: 1;
    position: relative;
    box-sizing: border-box; }
    .main-page-f__right > * {
      position: relative;
      z-index: 1;
      width: calc(1320px - 544px + ((100% - 1320px)/2));
      box-sizing: border-box; }
    .main-page-f__right::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url("/assets/img/advanages.png") repeat center;
      opacity: .8;
      position: absolute;
      top: 0;
      left: 0; }

@media (max-width: 1360px) {
  .main-page-f .title {
    padding: 0; }
  .main-page-f__left {
    width: auto; }
    .main-page-f__left > * {
      width: auto; } }

@media (max-width: 1180px) {
  .main-page-f {
    display: block; }
  .main-page-f__right > *, .main-page-f__left > * {
    width: 100% !important; }
  .main-page-news {
    padding: 0 24px; }
  .main-page-f__left {
    padding-bottom: 60px; }
  .main-page-news__content > *:nth-child(n+2) {
    margin-top: 0; }
  .main-page-news__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px; } }

@media (max-width: 660px) {
  .main-page-news__content {
    grid-template-columns: 1fr; } }

@media (max-width: 530px) {
  .main-page-news__all .button {
    width: calc(100% - 10px); }
    .main-page-news__all .button > * {
      width: 100%; } }

.our-partners {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px;
  padding: 80px 10px 140px 90px;
  align-items: baseline; }

@media (max-width: 1320px) {
  .our-partners {
    padding: 75px 40px 118px 70px; } }

@media (max-width: 1180px) {
  .our-partners {
    padding: 70px;
    text-align: center; } }

@media (max-width: 830px) {
  .our-partners {
    padding: 50px;
    grid-row-gap: 50px;
    grid-column-gap: 46px; } }

@media (max-width: 700px) {
  .our-partners {
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 530px) {
  .our-partners {
    grid-template-columns: 1fr;
    padding: 24px; } }

.contacts {
  background: url("/assets/img/contacts.png") no-repeat center top;
  padding-top: 68px;
  padding-bottom: 86px; }
  .contacts__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; }
  .contacts__logo {
    width: 200px;
    height: 200px;
    background: url(/assets/img/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 84px; }
  .contacts__links {
    font-size: 17px;
    font-weight: 300;
    flex: 1;
    margin-right: 20px; }
    .contacts__links a {
      display: inline-block;
      position: relative;
      padding-left: 18px; }
      .contacts__links a::before {
        content: '';
        display: block;
        border-top: 2px solid #3A4248;
        border-left: 2px solid #3A4248;
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(calc(-50% - 2px)) rotate(-135deg); }
    .contacts__links > * {
      margin-bottom: 24px; }
  .contacts__phones > * {
    display: block; }
    .contacts__phones > *:nth-child(n+2) {
      margin-top: 52px; }
  .contacts__address {
    flex: 2; }
    .contacts__address > *:nth-child(n+2) {
      margin-top: 60px; }

.address__title {
  font-size: 18px; }

.address__desc {
  font-size: 14px;
  font-weight: 300;
  color: #7F7F7F;
  margin-top: 15px; }

@media (max-width: 1360px) {
  .contacts__wrapper {
    padding: 0 24px; }
  .contacts {
    background-position: top right; } }

@media (max-width: 1130px) {
  .contacts__logo {
    width: 160px;
    height: 160px;
    margin-right: 40px; } }

@media (max-width: 1080px) {
  .contacts__logo {
    width: 120px;
    height: 120px;
    margin-right: 24px; } }

@media (max-width: 990px) {
  .contacts__logo {
    display: none; } }

@media (max-width: 836px) {
  .contacts {
    background: transparent; } }

@media (max-width: 820px) {
  .contacts__links {
    display: none; } }

@media (max-width: 610px) {
  .contacts__wrapper {
    display: block; }
  .contacts__address > *:nth-child(n+2) {
    margin-top: 30px; }
  .contacts__phones {
    margin-top: 50px; }
    .contacts__phones .phone__label > * {
      display: inline-block; }
  .contacts__phones > *:nth-child(n+2) {
    margin-top: 38px; } }

.contacts_sec-page {
  background: transparent; }

.footer {
  background: #AD253C url(/assets/img/tab.png) repeat center;
  padding: 17px 0 15px;
  color: #FFF;
  font-size: 14px; }
  .footer__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr; }
  .footer__copy {
    text-align: center; }
  .footer__socials {
    text-align: center; }

@media (max-width: 1360px) {
  .footer {
    padding-left: 24px;
    padding-right: 24px; } }

@media (max-width: 510px) {
  .footer__wrapper {
    grid-template-columns: 1fr; }
  .footer__dev {
    display: none; } }

.socials {
  text-align: right; }
  .socials > * {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    background-size: contain; }
  .socials__fb {
    width: 9px;
    height: 17px;
    background: url("/assets/img/socials/fb.svg") no-repeat center; }
  .socials__vk {
    width: 18px;
    height: 11px;
    background: url("/assets/img/socials/vk.svg") no-repeat center; }
  .socials__ok {
    width: 11px;
    height: 17px;
    background: url("/assets/img/socials/ok.svg") no-repeat center; }
  .socials__tw {
    width: 16px;
    height: 14px;
    background: url("/assets/img/socials/tw.svg") no-repeat center; }

@media (max-width: 510px) {
  .footer .socials {
    text-align: center;
    margin-top: 20px; } }

.page {
  max-width: 1320px;
  margin: 50px auto; }
  .page .title {
    padding: 0 !important; }
  .page__products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 90px;
    grid-column-gap: 90px; }

@media (max-width: 1360px) {
  .page {
    padding: 0 24px; } }

@media (max-width: 1080px) {
  .page__products {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 60px;
    grid-column-gap: 40px; } }

@media (max-width: 820px) {
  .page__products {
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 380px) {
  .page__products {
    grid-template-columns: 1fr; } }

.page-product__list {
  position: relative;
  font-weight: 300;
  font-size: 16px; }
  .page-product__list span {
    font-weight: 400; }
  .page-product__list li:nth-child(n+2) {
    margin-top: 24px; }

.page-product__top {
  display: block; }
  .page-product__top::after {
    content: '';
    display: block;
    width: 100%;
    clear: both; }
  .page-product__top .slick-slide {
    outline: none;
    padding-left: 126px; }

.page-product__left {
  width: calc(1320px - 660px);
  margin-left: calc(((100% - 1320px)/2));
  position: relative; }
  .page-product__left::before {
    content: '';
    display: block;
    width: 107px;
    height: 44px;
    background: url("/assets/img/product-top.svg") no-repeat center;
    position: absolute;
    bottom: -55px;
    right: 105px; }

.page-product__photo {
  padding-top: 60px;
  width: calc((1320px - 660px) + ((100% - 1320px)/2));
  position: relative;
  float: right; }
  .page-product__photo::before {
    content: '';
    display: block;
    width: 100%;
    height: 228px;
    position: absolute;
    top: 0;
    left: 0;
    background: url(/assets/img/advanages.png) repeat center; }
  .page-product__photo .slick-arrow {
    position: absolute;
    bottom: 10px;
    right: calc((100% - 1320px) + 660px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    background: #30415D;
    cursor: pointer;
    transition: .4s;
    z-index: 1; }
    .page-product__photo .slick-arrow:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-left: 2px solid #FFF;
      border-top: 2px solid #FFF;
      position: absolute;
      top: 50%;
      left: calc(50% - 2px);
      transform: translate(-50%, -50%) rotate(135deg); }
    .page-product__photo .slick-arrow.slick-prev {
      right: calc((100% - 1320px) + 730px); }
      .page-product__photo .slick-arrow.slick-prev:before {
        left: calc(50% + 2px);
        transform: translate(-50%, -50%) rotate(-45deg); }
    .page-product__photo .slick-arrow:hover {
      opacity: .81; }

.page-product__gallery {
  z-index: 1;
  position: relative; }

.page__news {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px; }
  @media (max-width: 1160px) {
    .page__news {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 60px;
      grid-column-gap: 60px; } }
  @media (max-width: 630px) {
    .page__news {
      grid-template-columns: 1fr;
      grid-row-gap: 60px;
      grid-column-gap: 60px; } }

.page__receipts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px; }
  @media (max-width: 1160px) {
    .page__receipts {
      grid-row-gap: 40px;
      grid-column-gap: 40px; } }
  @media (max-width: 810px) {
    .page__receipts {
      grid-template-columns: 1fr; } }

.receipt__title {
  font-size: 20px;
  margin-top: 20px; }

.receipt__pic img {
  width: 100%; }

.page__product-desc {
  margin-top: 70px;
  margin-bottom: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px;
  padding-left: 76px;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.9; }
  .page__product-desc::before {
    content: '';
    display: block;
    width: 24px;
    height: 86px;
    background: url("/assets/img/product-desc.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0; }
  @media (max-width: 900px) {
    .page__product-desc {
      grid-template-columns: 1fr;
      grid-row-gap: 40px;
      grid-column-gap: 40px;
      padding-left: 34px; }
      .page__product-desc::before {
        width: 16px; } }

@media (max-width: 1360px) {
  .page__product-desc {
    padding-right: 24px; }
  .page-product__photo, .page-product__left {
    width: 50%;
    margin-left: 0; }
  .page-product__photo .slick-arrow {
    right: 24px; }
  .page-product__photo .slick-arrow.slick-prev {
    right: 94px; }
  .page-product__list {
    padding: 0 24px; }
  .page-product__top .slick-slide {
    padding-left: 40px;
    box-sizing: border-box; } }

@media (max-width: 950px) {
  .page-product__photo {
    float: none;
    width: 100%; }
  .page-product__left {
    width: 100%;
    margin-top: 40px; }
    .page-product__left::before {
      display: none; } }

.receipt-time {
  font-size: 13px;
  color: #FFF;
  padding: 4px 6px 4px 40px;
  text-transform: uppercase;
  display: inline-block;
  background: #AD253C;
  vertical-align: 5px;
  margin-left: 20px; }

.page-receipt__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px; }

.page-receipt__title {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 40px; }

.page-receipt__photo {
  width: 620px; }
  .page-receipt__photo img {
    width: 100%; }

.page-receipt__desc {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.7; }
  .page-receipt__desc > * {
    margin-bottom: 40px; }

.page-receipt__list ul {
  width: 650px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  grid-auto-rows: min-content; }
  .page-receipt__list ul > * {
    padding-left: 20px;
    position: relative; }
    .page-receipt__list ul > *::before {
      content: '';
      display: block;
      border-top: 2px solid #3A4248;
      border-left: 2px solid #3A4248;
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(calc(-50% - 2px)) rotate(-135deg);
      transform: translateY(calc(-50% - 2px)) rotate(-135deg); }

@media (max-width: 1360px) {
  .page-receipt__photo {
    width: 40%; }
  .page-receipt__list ul {
    width: 50%;
    grid-template-columns: 1fr; } }

@media (max-width: 860px) {
  .page-receipt__top {
    display: block; }
  .page-receipt__photo {
    width: 100%; }
  .page-receipt__list ul {
    width: 100%;
    grid-template-columns: 1fr;
    margin-top: 30px; } }

.breadcrumbs {
  color: #AAB4C0;
  font-size: 0;
  margin-bottom: 58px; }
  .breadcrumbs__home {
    width: 18px;
    height: 15px;
    background: url("/assets/img/breadcrumbs.svg") no-repeat center; }
  .breadcrumbs > * {
    display: inline-block;
    position: relative;
    font-size: 16px; }
    .breadcrumbs > *:not(:last-child) {
      margin-right: 36px; }
      .breadcrumbs > *:not(:last-child):before {
        content: '';
        display: block;
        width: 1px;
        height: 17px;
        background: #AAB4C0;
        position: absolute;
        top: 0;
        right: -18px; }
