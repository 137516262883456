.product {
  color: #3A4248;

  &_new {
    .product {

      &__pic {
        &::after {
          content: 'Новинка';
          display: inline-block;
          padding: 8px 8px 7px 40px;
          background: #AD253C;
          color: #FFF;
          text-transform: uppercase;
          font-size: 13px;
          line-height: .8;
          letter-spacing: 1px;
          font-weight: 500;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &__pic {
    max-width: 215px;
    margin: 0 auto;
    position: relative;

    img {
      width: 100%;
    }
  }

  &__info {
    max-width: 215px;
    margin: 16px auto 0;
    text-align: center;
    font-size: 14px;
  }

  &__name {
    line-height: 1.2;
    height: 32px;
    overflow: hidden;
  }

  &__key {
    font-weight: 300;
    margin-top: 10px;
  }
}