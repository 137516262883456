.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: .4s;

  &_second:not(.menu_open) {
    background: #AD253C url('/assets/img/menu.svg') repeat-x bottom -3px center;
    color: #FFF;

    .menu__wrapper {
      margin-top: 10px;
      margin-bottom: 5px;
      
      ul {
        color: #3A4248;
      }

      & > *:not(.menu-search) ul + a::after {
        border-bottom-color: #FFF;
        border-left-color: #FFF;
      }
    }

    .menu-search__close::before, .menu-search__close::after {
      background: #FFF;
    }

    .menu-search input {
      color: #FFF;
      background: url(/assets/img/search_white.svg) no-repeat left center;
      background-size: 20px;
    }
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 18px;
    height: 18px;
    z-index: 2;
    cursor: pointer;
    display: none;

    &::before, &::after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: #3A4248;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &_white {
    background: #FFF;
  }

  &__wrapper {
    max-width: 1320px;
    margin: 18px auto 0;
    font-size: 17px;
    font-weight: 300;
    position: relative;

    & > *:not(.menu-search)  {
      display: inline-block;
      cursor: pointer;
      margin-right: 40px;
      position: relative;

      &:hover {
        ul {
          display: block;
        }
      }

      &:first-child {
        margin-left: -10px;
      }

      & > a {
        padding: 10px;
        display: inline-block;
        position: relative;
        padding-bottom: 18px;
      }

      ul {
        display: none;
        position: absolute;
        bottom: 0;
        left: 10px;
        transform: translate(0, 100%);
        background: #FFF;
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.08);
        white-space: nowrap;

        & > li {
          a {
            padding: 15px 22px;
            position: relative;
            display: block;

            &::before {
              content: '';
              display: block;
              width: 4px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: #AD253C;
              opacity: 0;
              transition: .4s;
            }

            &:hover {
              &::before {
                opacity: 1;
              }
            }
          }
        }

        & + a {
          &::after {
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            border-bottom: 1px solid #3A4248;
            border-left: 1px solid #3A4248;
            position: absolute;
            top: 14px;
            right: -10px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.menu-search {
  position: absolute;
  top: 2px;
  right: 0;
  width: 0;
  transition: .4s;

  &__close {
    position: absolute;
    top: 10px;
    right: 0;
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    transition: .4s;

    &::before, &::after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: #3A4248;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  input {
    color: #3A4248;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    padding-left: 20px;
    background: url('/assets/img/search.svg') no-repeat left center;
    height: 30px;
    border: 0;
    font-size: 17px;
    cursor: pointer;
    transition: .4s;
    width: 100%;
    background-size: 20px;
    padding-top: 5px;
    position: relative;
    z-index: 2;
    transform: translateX(-100%);

    &::placeholder {
      color: inherit;
    }
  }
}

.menu_search {
  .menu {
    &__wrapper {
      & > *:not(.menu-search) {
        opacity: 0;
      }
    }
  }

  .menu-search {
    width: 100%;

    input {
      padding-left: 48px;
      cursor: auto;
      transform: translateX(0);
    }

    &__close {
      z-index: 2;
      opacity: 1;
    }
  }
}

@media(max-width: 1360px) {
  .menu__wrapper {
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media(max-width: 1300px) {
  .menu__wrapper {
    margin-top: 13px;
  }

  .menu__wrapper > *:not(.menu-search) {
    margin-right: 24px;
  }
}

@media(max-width: 1130px) {
  .menu-search {
    display: none;
  }

  .menu__wrapper {
    font-size: 16px;
  }

  .menu__wrapper > *:not(.menu-search) {
    margin-right: 22px;
  }
}

@media(max-width: 1060px) {
  .menu__wrapper {
    background: #FFF;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding: 24px;
    padding-bottom: 12px;
    box-sizing: border-box;
    transform: translate(0, -100%);
    transition: .4s;
  }

  .menu_open {
    .menu__wrapper {
      transform: translate(0, 0);
    }
  }

  .menu__wrapper > *:not(.menu-search) ul {
    display: none!important;
  }

  .menu__wrapper > *:not(.menu-search) a {
    &::after {
      display: none!important;
    }
  }

  .menu__wrapper > *:not(.menu-search):first-child {
    margin-left: 0;
  }
}

@media(max-width: 900px) {
  .menu__wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media(max-width: 600px) {
  .menu__wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

.mobile-menu {
  position: fixed;
  top: 24px;
  right: 24px;
  width: 42px;
  height: 42px;
  background: #30415d;
  z-index: 2;
  cursor: pointer;
  display: none;

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 16px;
    position: absolute;
    border-top: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  &::after {
    content: '';
    display: block;
    height: 2px;
    background: #FFF;
    width: 20px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  @media(max-width: 1060px) {
    display: block;
  }
}

.menu_open {
  .menu__close {
    display: block;
  }
}