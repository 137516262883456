@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&subset=cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=Raleway:600');

@font-face { font-family: 'Madelyn-Regular';
  src: local('Madelyn-Regular'), url('/assets/fonts/medlun.woff') format('woff')
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline
}

body {
  line-height: 1.2;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #3A4248;
}

button, input, select {
  outline: none;
}

a {
  cursor: pointer;
  color: inherit;
  outline: none;
  text-decoration: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: none;
}

textarea:focus {
  outline: 0;
}

.s-background {
  background: url('/assets/img/s-background.png') no-repeat center top;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration { display: none; }