.socials {
  text-align: right;

  & > * {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
  }

  &__fb {
    width: 9px;
    height: 17px;
    background: url('/assets/img/socials/fb.svg') no-repeat center;
  }

  &__vk {
    width: 18px;
    height: 11px;
    background: url('/assets/img/socials/vk.svg') no-repeat center;
  }

  &__ok {
    width: 11px;
    height: 17px;
    background: url('/assets/img/socials/ok.svg') no-repeat center;
  }

  &__tw {
    width: 16px;
    height: 14px;
    background: url('/assets/img/socials/tw.svg') no-repeat center;
  }
}

@media(max-width: 510px) {
  .footer .socials {
    text-align: center;
    margin-top: 20px;
  }
}