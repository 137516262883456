.about-us {
  width: 100%;
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.5);
  background: url('/assets/img/about.png') no-repeat center;
  background-size: cover;
  color: #FFF;
  position: relative;

  & > * {
    position: relative;
    z-index: 1;
  }

  &__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  &__text {
    padding-top: 100px;
    padding-bottom: 74px;
    max-width: 730px;
  }

  &__title {
    font-size: 35px;
    font-weight: 500;
    line-height: 1.2;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 85px;
      height: 26px;
      background: url('/assets/img/about.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: -48px;
      left: 0;
    }
  }

  &__desc {
    font-size: 20px;
    line-height: 1.5;
    padding-left: 30px;
    opacity: .8;
    margin-top: 34px;
  }

  &__num {
    font-size: 108px;
    font-family: 'Raleway';
    font-weight: 600;

    span {
      font-family: 'Roboto';
      font-size: 50px;
      font-weight: 400;
    }
  }

  &__int {
    padding-top: 36px;
    padding-right: 60px;
  }

  &__regions {
    display: flex;

    .about-us__num-desc {
      background: url('/assets/img/about-us_reg.svg') no-repeat center top 9px;
      margin-top: 31px;
      margin-left: 40px;
      padding-left: 6px;
      padding-right: 24px;
      line-height: 1.4;
    }
  }

  &__products {
    display: flex;
    align-items: flex-end;
    margin-top: 44px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -13px;
      left: 72px;
      width: 111px;
      height: 10px;
      background: url('/assets/img/about-us.svg') no-repeat center;
      background-size: contain;
    }

    .about-us__num-desc {
      background: url('/assets/img/about-us_prod.svg') no-repeat center top 9px;
      margin-right: 40px;
      padding-right: 6px;
      line-height: 1.4;
      text-align: right;
    }
  }

  &__num-desc {
    font-size: 16px;
    font-weight: 300;
  }
}

@media(max-width: 1360px) {
  .about-us {
    &__wrapper {
      padding: 0 24px;
      box-sizing: border-box;
    }
  }
}

@media(max-width: 1250px) {
  .about-us__int {
    padding-right: 0;
  }
}

@media(max-width: 1190px) {
  .about-us__title {
    font-size: 28px;
  }

  .about-us__text {
    max-width: 585px;
  }

  .about-us__desc {
    font-size: 18px;
    opacity: 1;
  }
}

@media(max-width: 1040px) {
  .about-us {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url(/assets/img/advanages.png) repeat center;
      opacity: .8;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .about-us__wrapper {
    display: block;
    position: relative;
    z-index: 1;
  }

  .about-us__text {
    max-width: inherit;
    padding-top: 78px;
    padding-bottom: 0;
  }

  .about-us__int {
    display: inline-block;
  }

  .about-us__wrapper {
    text-align: center;
    padding-bottom: 40px;
  }

  .about-us__desc {
    padding-left: 0;
  }

  .about-us__regions .about-us__num-desc, .about-us__text {
    text-align: left;
  }
}

@media(max-width: 440px) {
  .about-us__num {
    font-size: 88px;

    span {
      font-size: 34px;
    }
  }
}

@media(max-width: 350px) {
  .about-us__num {
    font-size: 84px;

    span {
      font-size: 32px;
    }
  }

  .about-us__regions .about-us__num-desc {
    margin-left: 18px;
  }

  .about-us__products .about-us__num-desc {
    margin-right: 18px;
  }
}

@media(max-width: 320px) {
  .about-us__num {
    font-size: 80px;

    span {
      font-size: 28px;
    }
  }

  .about-us__regions .about-us__num-desc {
    margin-left: 12px;
  }

  .about-us__products .about-us__num-desc {
    margin-right: 12px;
  }
}

@media(max-width: 320px) {
  .about-us__num {
    font-size: 60px;
  }
}