.advantages {
  width: 100%;
  background: url('/assets/img/advanages.png') repeat center;
  color: #FFF;
  font-size: 16px;
  padding-bottom: 140px;
  position: relative;

  & > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 35px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url('/assets/img/advanages.svg') repeat-x bottom center;
    background-size: auto 100%;
  }

  &__wrapper {
    max-width: 1320px;
    margin: 0 auto;
  }

  &__title {
    text-transform: uppercase;
    color: #FFF;
    opacity: .2;
    font-weight: 500;
    line-height: .77;
    font-size: 58px;
    letter-spacing: 3px;
    margin-bottom: 56px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    max-width: 1080px;
    margin: 0 auto;
  }
}

.advantage {
  text-align: center;

  &__pic {
    height: 107px;
    margin-bottom: 26px;
    position: relative;

    &::after {
      content: '';
      width: 54px;
      height: 1px;
      background: #FFF;
      position: absolute;
      bottom: -26px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__desc {
    line-height: 1.5;
    font-weight: 100;
    padding-top: 17px;
    display: block;

    span {
      font-weight: 400;
    }
  }
}

@media(max-width: 1360px) {
  .advantages {
    &__title {
      padding: 0 24px;
    }
  }
}

@media(max-width: 1180px) {
  .advantages {
    padding-bottom: 80px;
  }

  .advantage {
    padding: 30px 24px;
  }
}

@media(max-width: 870px) {
  .advantages {
    padding-top: 64px;
  }

  .advantages__title {
    font-size: 35px;
    line-height: 1.3;
    opacity: 1;
    text-transform: none;
    letter-spacing: inherit;
    margin-bottom: 42px;
  }
}

@media(max-width: 980px) {
  .advantages__list {
    flex-wrap: wrap;
    justify-content: center;

    & > * {
      width: 33%;
      box-sizing: border-box;
    }
  }
}

@media(max-width: 670px) {
  .advantages__list {
    & > * {
      width: 50%;
    }
  }
}

@media(max-width: 400px) {
  .advantages__list {
    & > * {
      width: 100%;
    }
  }

  .advantage__pic {
    height: auto;
  }
}