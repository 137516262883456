.all-production {
  max-width: 1320px;
  margin: 0 auto 74px;
  color: #3A4248;

  &__tabs {
    font-size: 0;
    padding-right: 122px;
    margin: -16px 0 0 -25px;

    & > * {
      display: inline-block;
      font-size: 16px;
      padding: 12px 42px;
      font-weight: 300;
      border: .5px solid rgba(#3A4248, .5);
      cursor: pointer;
      margin-left: 25px;
      transition: .4s;
      margin-top: 16px;

      &:hover {
        background: #30415D;
        color: #FFF;
      }

      &.active {
        background: #30415D url('/assets/img/tab.png') repeat center;
        color: #FFF;
      }
    }
  }

  &__content {
    margin-top: 90px;

    .slick-slide {
      outline: none;
    }

    .slick-prev, .slick-next {
      content: '';
      display: block;
      position: absolute;
      top: -120px;
      right: 20px;
      font-size: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      border: 0;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-right: 2px solid #30415D;
        border-bottom: 2px solid #30415D;
        transform: rotate(-45deg);
      }
    }

    .slick-prev {
      right: 65px;

      &::before {
        border-right: 0;
        border-bottom: 0;
        border-left: 2px solid #30415D;
        border-top: 2px solid #30415D;
      }
    }
  }
}

@media(max-width: 1320px) {
  .all-production {
    &__tabs {
      padding-left: 24px;
    }
  }
}

@media(max-width: 1020px) {

  .all-production {
    &__tabs {
      padding-right: 24px;
    }

    &__content {
      margin-top: 130px;

      .slick-next, .slick-prev {
        right: 72px;
        top: -80px;
      }

      .slick-next {
        right: 36px;
      }
    }
  }
}
