.main-page-f {
  display: flex;

  &__left {
    text-align: right;
    width: calc(1320px - 776px + ((100% - 1320px)/2));

    & > * {
      display: inline-block;
      text-align: left;
      width: 544px;
      box-sizing: border-box;
      padding-top: 20px;
    }
  }

  &__right {
    background: url('/assets/img/partners.png') no-repeat center left;
    background-size: cover;
    flex: 1;
    position: relative;
    box-sizing: border-box;

    & > * {
      position: relative;
      z-index: 1;
      width: calc(1320px - 544px + ((100% - 1320px)/2));
      box-sizing: border-box;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url("/assets/img/advanages.png") repeat center;
      opacity: .8;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@media(max-width: 1360px) {
  .main-page-f .title {
    padding: 0;
  }

  .main-page-f__left {
    width: auto;

    & > * {
      width: auto;
    }
  }
}

@media(max-width: 1180px) {
  .main-page-f {
    display: block;
  }

  .main-page-f__right > *, .main-page-f__left > * {
    width: 100%!important;
  }

  .main-page-news {
    padding: 0 24px;
  }

  .main-page-f__left {
    padding-bottom: 60px;
  }

  .main-page-news__content > *:nth-child(n+2) {
    margin-top: 0;
  }

  .main-page-news__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
}


@media(max-width: 660px) {
  .main-page-news__content {
    grid-template-columns: 1fr;
  }
}

@media(max-width: 530px) {
  .main-page-news__all {
    .button {
      width: calc(100% - 10px);

      & > * {
        width: 100%;
      }
    }
  }
}