.breadcrumbs {
  color: #AAB4C0;
  font-size: 0;
  margin-bottom: 58px;

  &__home {
    width: 18px;
    height: 15px;
    background: url('/assets/img/breadcrumbs.svg') no-repeat center;
  }

  & > * {
    display: inline-block;
    position: relative;
    font-size: 16px;

    &:not(:last-child) {
      margin-right: 36px;

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 17px;
        background: #AAB4C0;
        position: absolute;
        top: 0;
        right: -18px;
      }
    }
  }
}