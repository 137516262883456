.button {
  position: relative;
  display: inline-block;
  line-height: 0;
  transition: .4s;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    transition: .4s;
  }

  & > * {
    background-color: #AD253C;
    color: #FFF;
    line-height: 1.2;
    padding: 18px 40px 16px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 500;
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    text-align: center;

    &::after {
      transition: .4s;
    }
  }
}

.button_fish {
  &::after {
    content: '';
    display: block;
    background: url('/assets/img/button/fish.svg') no-repeat center;
    width: 42px;
    height: 19px;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: -22px;
    transform: translateY(-50%);
  }

  & > * {
    &::after {
      content: '';
      display: block;
      background: url('/assets/img/button/fish_white.svg') no-repeat center;
      width: 42px;
      height: 19px;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: -22px;
      transform: translateY(-50%);
    }
  }

  &:hover {
    &::after {
      right: -32px;
    }

    & > * {
      &::after {
        right: -32px;
      }
    }
  }
}

.button_form {
  width: calc(100% - 22px);

  & > span {
    background: #30415D;
    width: 100%;
  }

  &::after {
    content: '';
    display: block;
    background: url('/assets/img/button/arrow.svg') no-repeat center;
    width: 42px;
    height: 19px;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: -22px;
    transform: translateY(-50%);
  }

  & > * {
    &::after {
      content: '';
      display: block;
      background: url('/assets/img/button/arrow_white.svg') no-repeat center;
      width: 42px;
      height: 19px;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: -22px;
      transform: translateY(-50%);
    }
  }

  &:hover {
    &::after {
      right: -32px;
    }

    & > * {
      &::after {
        right: -32px;
      }
    }
  }
}

.button_news {

  &::after {
    content: '';
    display: block;
    background: url('/assets/img/button/news.svg') no-repeat center;
    width: 25px;
    height: 20px;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: -14px;
    transform: translateY(-50%);
  }

  & > * {
    &::after {
      content: '';
      display: block;
      background: url('/assets/img/button/news_white.svg') no-repeat center;
      width: 25px;
      height: 20px;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: -14px;
      transform: translateY(-50%);
    }
  }

  &:hover {
    &::after {
      right: -20px;
    }

    & > * {
      &::after {
        right: -20px;
      }
    }
  }
}

@media(max-width: 1086px) {
  .button {
    & > * {
      font-size: 14px;
    }
  }
}