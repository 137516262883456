.header {
  width: 100%;
  position: relative;
  min-height: 880px;

  &__desc {
    font-family: 'Madelyn-Regular';
    font-size: 55px;
    margin-top: 84px;
    color: #AAB4C0;
  }

  &__slider {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(1320px - 740px + ((100% - 1320px)/2));
    overflow: hidden;
  }

  &__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 140px;
  }

  &__text-slider {
    margin-top: 88px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 169px;
      height: 89px;
      background: url('/assets/img/fish.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: -78px;
      right: 49%;
      z-index: 1;
      pointer-events: none;
    }
  }

  &__footer {
    margin-top: 60px;
    padding-right: 70px;
  }

  &__second {
    display: flex;
    justify-content: space-between;

    .phone {
      .phone__label > * {
        display: inline-block;
      }
    }
  }
}

.header-slider {
  &__block {
    position: relative;
    height: 880px!important;
    overflow: hidden;
    outline: none;

    img {
      position: absolute;
      top: 50%;
      right: 0;
      height: 100%;
      transform: translateY(-50%);
    }
  }
}

.header-logo {
  display: flex;
  align-items: center;

  &__pic {
    width: 200px;
    height: 200px;
    background: url('/assets/img/logo.svg');
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    margin-right: 60px;
  }

  &__text {
    font-size: 45px;
    font-weight: 700;
    color: #3A4248;
    line-height: 1.3;
    padding-top: 26px;
    text-transform: capitalize;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 111px;
      height: 10px;
      background: url('/assets/img/logo-wave.svg') no-repeat left;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.text-slider {
  background: rgba(#30415D, .81);
  color: #FFF;

  &__block {
    outline: none;
    position: relative;
    padding-top: 50px;
    padding-left: 45px;
    padding-bottom: 73px;
    padding-right: 200px;
  }

  &__text {
    line-height: 1.7;
    font-size: 20px;
    position: relative;
    z-index: 1;
  }

  &__title {
    position: absolute;
    bottom: 0;
    right: 46px;
    font-size: 108px;
    text-transform: uppercase;
    color: rgba(#FFF, .1);
    font-weight: 700;
    line-height: .7;
    letter-spacing: 8px;
  }

  .slick-arrow {
    position: absolute;
    bottom: -167px;
    right: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    background: #30415D;
    cursor: pointer;
    transition: .4s;

    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-left: 2px solid #FFF;
      border-top: 2px solid #FFF;
      position: absolute;
      top: 50%;
      left: calc(50% - 2px);
      transform: translate(-50%, -50%) rotate(135deg);
    }

    &.slick-prev {
      right: 70px;
      &:before {
        left: calc(50% + 2px);
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &:hover {
      opacity: .81;
    }
  }
}

.header-footer {
  display: flex;
  justify-content: space-between;
  width: calc(1320px - 580px + ((100% - 1320px)/2));
  box-sizing: border-box;
}

@media(max-width: 1360px){
  .header-logo {
    padding: 0 24px;
  }

  .header-footer {
    padding-left: 24px;
  }

  .text-slider .slick-arrow.slick-next {
    right: 24px;
  }

  .text-slider .slick-arrow.slick-prev {
    right: 94px;
  }
}

@media(max-width: 1240px) {
  .header-logo {
    &__text {
      font-size: 35px;
    }
  }

  .text-slider {
    &__title {
      font-size: 80px;
    }
  }
}

@media(max-width: 1086px) {
  .header {
    min-height: auto;
  }

  .header-logo {
    &__text {
      font-size: 30px;
    }

    &__pic {
      width: 180px;
      height: 180px;
    }
  }

  .text-slider {
    &__text {
      font-size: 18px;
      line-height: 1.4;
    }

    .slick-arrow {
      bottom: inherit;
      top: -80px;
    }
  }

  .header-footer {
    padding-right: 24px;
    width: 100%;
  }

  .header-slider__block {
    height: 604px!important;
  }
}

@media(max-width: 924px) {
  .header-logo {
    &__pic {
      margin-right: 40px;
    }
  }
}

@media(max-width: 884px) {
  .header-logo {
    &__text {
      font-size: 28px;
    }
  }
}

@media(max-width: 840px) {
  .header__slider {
    display: none;
  }
}

@media(max-width: 780px) {
  .text-slider {
    &__block {
      padding: 24px;
      padding-bottom: 46px;
    }

    &__title {
      right: 24px;
      font-size: 44px;
    }
  }
}

@media(max-width: 530px) {
  .header-footer {
    display: block;

    &__phone {
      margin-top: 32px;
      text-align: center;

      .phone {
        display: inline-block;
      }
    }

    &__products {
      .button {
        width: calc(100% - 30px);

        & > * {
          width: 100%;
        }
      }
    }
  }
}

@media(max-width: 500px) {
  .header-logo {
    &__text {
      font-size: 26px;
    }
  }

  .text-slider {

    &__text {
      font-size: 16px;
    }

    &__title {
      font-size: 36px;
    }

    .slick-arrow {
      width: 42px;
      height: 42px;
      top: -60px;

      &.slick-prev {
        right: 78px;
      }

      &::before {
        width: 10px;
        height: 10px;
      }
    }
  }
}

@media(max-width: 430px) {
  .header-logo {
    display: block;
    padding-bottom: 30px;

    &__pic {
      width: 100%;
      height: 200px;
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
}

@media(max-width: 1060px) {
  .header__wrapper {
    padding-top: 45px;
  }
}

@media(max-width: 640px) {
  .header__wrapper {
    padding-top: 30px;
  }
}

.header-second {
  &__right {
    text-align: right;
  }
}

.header_second {
  min-height: auto;

  .header__text-slider {
    margin-top: 30px;

    &::before {
      right: 42%;
    }
  }
}

@media(max-width: 1360px) {
  .header-second__right {
    padding-right: 24px;
  }
}

@media(max-width: 1060px) {
  .header__desc {
    margin-top: 40px;
  }

  .header-second__right {
    padding-top: 40px;
  }
}

@media(max-width: 880px) {
  .header__desc {
    font-size: 40px;
  }
}

@media(max-width: 784px) {
  .header__desc {
    display: none;
  }
}

@media(max-width: 760px) {
  .header__second {
    display: block;
  }

  .header-second__right {
    text-align: center;
    padding: 30px 24px 0;
  }

  .header_second .header__text-slider {
    display: none;
  }
}