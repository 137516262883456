.form {
  padding-top: 86px;
  padding-bottom: 86px;

  & > * {
    position: relative;
    z-index: 1;
  }

  &__wrapper {
    max-width: 1320px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    box-sizing: border-box;
    padding-left: 70px;
    grid-row-gap: 56px;
    grid-column-gap: 116px;
  }

  &__desc {
    font-size: 20px;
    line-height: 1.3;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 46px;
      height: 30px;
      background: url('/assets/img/quote.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: 0;
      left: -70px;
    }
  }

  &__input {
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 50px;
      height: 2px;
      background: #30415D;
    }

    input, select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 18px;
      color: inherit;
      font-family: inherit;
      width: 100%;
      border: 0;
      background: transparent;
      border-bottom: 1px solid rgba(#3A4248, .5);
      padding-bottom: 12px;

      &::placeholder {
        color: #30415D;
      }
    }

    select {
      background: url('/assets/img/select.svg') no-repeat center right 10px;
      background-size: 11px auto;
      cursor: pointer;
      padding-right: 22px;
    }
  }

  &__title {
    display: block;
    font-weight: 300;
    font-size: 14px;
    color: #7F7F7F;
    margin-bottom: 22px;
  }

  &__button {
    padding-top: 24px;
  }

  &__privacy {
    font-size: 14px;
    font-weight: 300;
    margin-top: 22px;

    label {
      cursor: pointer;
      line-height: 1.4;
      display: flex;
    }

    input {
      width: 13px;
      height: 13px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid rgba(#3A4248, .5);
      display: inline-block;
      line-height: 0;
      vertical-align: -5px;
      border-radius: 50%;
      margin-right: 10px;
      margin-left: 0;
      cursor: pointer;
      margin-top: 2px;

      &:checked {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30415d+37,ffffff+38,ffffff+100 */
        background: rgb(48,65,93); /* Old browsers */
        background: -moz-radial-gradient(center, ellipse cover, rgba(48,65,93,1) 37%, rgba(255,255,255,1) 38%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, rgba(48,65,93,1) 37%,rgba(255,255,255,1) 38%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, rgba(48,65,93,1) 37%,rgba(255,255,255,1) 38%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30415d', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
    }
    
    a {
      border-bottom: 1px dashed rgba(#3A4248, .5);
      transition: .4s;

      &:hover {
        border-color: transparent;
      }
    }
  }
}

@media(max-width: 1360px) {
  .form__wrapper {
    padding-right: 24px;
    grid-column-gap: 60px;
  }
}

@media(max-width: 1170px) {
  .form__wrapper {
    grid-column-gap: 40px;
  }
}

@media(max-width: 1120px) {
  .form__wrapper {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
  }
}

@media(max-width: 880px) {
  .form__wrapper {
    grid-column-gap: 24px;
  }
}

@media(max-width: 750px) {
  .form__desc {
    padding-left: 40px;
    font-size: 16px;

    &:before {
      width: 23px;
      height: 15px;
      top: 6px;
      left: 0px;
    }
  }

  .form__wrapper {
    padding-left: 24px;
    grid-template-columns: 1fr;
    grid-row-gap: 46px;
  }
}

.form__input.error .form__title {
  color: #AD253C;
}

.form__privacy .form__privacy-error {
  display: none;
  margin-top: 20px;
  border: 2px solid #AD253C;
  color: #AD253C;
  padding: 13px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.2;
}

.form__privacy.error .form__privacy-error {
  display: block;
}

.hide-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  z-index: 10;
  display: none;
  overflow: auto;
}

.hide-window .hide-window__hide-window-wrapper.hide-window-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 44px;
  outline: 10px solid rgba(255, 255, 255, .7);
  background: #FFF;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  text-align: center;

  .title {
    margin-bottom: 20px;
  }
}

.hide-window .hide-window__hide-window-wrapper.hide-window-wrapper::after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}

.hide-window .hide-window__hide-window-wrapper.hide-window-wrapper .hide-window-wrapper__close {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAN5JREFUeNqslYsNwyAMBRmhozBCR/EoHSEjeISMwihs4CZpaRPzsV+pJRQpgrsoPEMQkaDGbRv8fgbn2OeTft8CJ3lVcgpYvkU9+BksTgFLXaThLbAlYOkXFfgI3BOw2EX7xLiN7JicTpvtqaV8iVeQnWDWGxqBxSa4FcVZAY9yPiNgq4l+FXCLM+q8BwCPCJzAX5Jbgn+AuwINXiZjeBEEsKUhAQpegU6OJXZI3LwxXb2byGAfHIecJyUMNtrneLZiyMYVpwWXc3+UcwusBdWN1VtwB27+IqiuwqcAAwCxKFl9fhw5jwAAAABJRU5ErkJggg==') no-repeat center;
  position: absolute;
  top: -10px;
  right: -48px;
  width: 23px;
  height: 23px;
  opacity: .7;
  transition: .4s;
  cursor: pointer;
}