.main-page {
  padding-top: 94px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 1270px;
    height: 1270px;
    position: absolute;
    top: -237px;
    left: 0;
    transform: rotate(-135deg) translateY(calc(-50% - 263px));
    background: #9EA6B2;
    opacity: .15;
    z-index: 1;
    pointer-events: none;
  }
}

.main-page-news {
  padding-right: 164px;

  &__all {
    margin-top: 50px;
  }
  
  &__link {
    color: #AD253C;
    font-size: 14px;
    font-weight: 300;
    margin-top: 26px;
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 23px;
      height: 10px;
      background: url('/assets/img/news.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: -40px;
      transform: translateY(-50%);
      transition: .4s;
    }

    &:hover {
      &::after {
        right: -50px;
      }
    }
  }

  &__title {
    font-size: 20px;
  }

  &__description {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 24px;
    color: #7F7F7F;
  }

  &__content {
    & > * {
      &:nth-child(n+2) {
        margin-top: 65px;
      }
    }
  }
}

@media(max-width: 1360px) {
  .main-page {
    &::before {
      width: 1100px;
      height: 1100px;
    }
  }
}

@media(max-width: 1190px) {
  .main-page {
    &::before {
      display: none;
    }
  }
}

@media(max-width: 1020px) {
  .main-page {
    padding-top: 60px;
  }
}

@media(max-width: 1360px) {
  .main-page-f .title {
    padding: 0;
  }

  .main-page-news {
    padding: 0 42px 0 24px;
  }
}