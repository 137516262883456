.page {
  max-width: 1320px;
  margin: 50px auto;

  .title {
    padding: 0!important;
  }

  &__products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 90px;
    grid-column-gap: 90px;
  }
}

@media(max-width: 1360px) {
  .page {
    padding: 0 24px;
  }
}

@media(max-width: 1080px) {
  .page {
    &__products {
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 60px;
      grid-column-gap: 40px;
    }
  }
}

@media(max-width: 820px) {
  .page {
    &__products {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media(max-width: 380px) {
  .page {
    &__products {
      grid-template-columns: 1fr;
    }
  }
}

.page-product {

  &__list {
    position: relative;
    font-weight: 300;
    font-size: 16px;



    span {
      font-weight: 400;
    }

    li:nth-child(n+2) {
      margin-top: 24px;
    }
  }

  &__top {
    display: block;
    
    &::after {
      content: '';
      display: block;
      width: 100%;
      clear: both;
    }

    .slick-slide {
      outline: none;
      padding-left: 126px;
    }
  }

  &__left {
    width: calc(1320px - 660px);
    margin-left: calc(((100% - 1320px)/2));
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 107px;
      height: 44px;
      background: url('/assets/img/product-top.svg') no-repeat center;
      position: absolute;
      bottom: -55px;
      right: 105px;
    }
  }

  &__photo {
    padding-top: 60px;
    width: calc((1320px - 660px) + ((100% - 1320px)/2));
    position: relative;
    float: right;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 228px;
      position: absolute;
      top: 0;
      left: 0;
      background: url(/assets/img/advanages.png) repeat center;
    }

    .slick-arrow {
      position: absolute;
      bottom: 10px;
      right: calc((100% - 1320px) + 660px);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0;
      font-size: 0;
      padding: 0;
      margin: 0;
      width: 60px;
      height: 60px;
      background: #30415D;
      cursor: pointer;
      transition: .4s;
      z-index: 1;

      &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-left: 2px solid #FFF;
        border-top: 2px solid #FFF;
        position: absolute;
        top: 50%;
        left: calc(50% - 2px);
        transform: translate(-50%, -50%) rotate(135deg);
      }

      &.slick-prev {
        right: calc((100% - 1320px) + 730px);
        &:before {
          left: calc(50% + 2px);
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &:hover {
        opacity: .81;
      }
    }
  }

  &__gallery {
    z-index: 1;
    position: relative;
  }
}

.page__news {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px;

  @media(max-width: 1160px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 60px;
    grid-column-gap: 60px;
  }

  @media(max-width: 630px) {
    grid-template-columns: 1fr;
    grid-row-gap: 60px;
    grid-column-gap: 60px;
  }
}

.page__receipts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px;

  @media(max-width: 1160px) {
    grid-row-gap: 40px;
    grid-column-gap: 40px;
  }

  @media(max-width: 810px) {
    grid-template-columns: 1fr;
  }
}

.receipt {
  &__title {
    font-size: 20px;
    margin-top: 20px;
  }

  &__pic {
    img {
      width: 100%;
    }
  }
}

.page__product-desc {
  margin-top: 70px;
  margin-bottom: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 90px;
  grid-column-gap: 90px;
  padding-left: 76px;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.9;

  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 86px;
    background: url('/assets/img/product-desc.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media(max-width: 900px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    padding-left: 34px;

    &::before {
      width: 16px;
    }
  }

}

@media(max-width: 1360px) {
  .page__product-desc {
    padding-right: 24px;
  }

  .page-product__photo, .page-product__left {
    width: 50%;
    margin-left: 0;
  }

  .page-product__photo .slick-arrow {
    right: 24px;
  }

  .page-product__photo .slick-arrow.slick-prev {
    right: 94px;
  }

  .page-product__list {
    padding: 0 24px;
  }

  .page-product__top .slick-slide {
    padding-left: 40px;
    box-sizing: border-box;
  }
}

@media(max-width: 950px) {
  .page-product__photo {
    float: none;
    width: 100%;
  }

  .page-product__left {
    width: 100%;
    margin-top: 40px;

    &::before {
      display: none;
    }
  }
}

.receipt-time {
  font-size: 13px;
  color: #FFF;
  padding: 4px 6px 4px 40px;
  text-transform: uppercase;
  display: inline-block;
  background: #AD253C;
  vertical-align: 5px;
  margin-left: 20px;
}

.page-receipt {
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  &__title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  &__photo {
    width: 620px;

    img {
      width: 100%;
    }
  }

  &__desc {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.7;

    & > * {
      margin-bottom: 40px;
    }
  }

  &__list ul {
    width: 650px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    grid-auto-rows: min-content;

    & > * {
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        display: block;
        border-top: 2px solid #3A4248;
        border-left: 2px solid #3A4248;
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(calc(-50% - 2px)) rotate(-135deg);
        transform: translateY(calc(-50% - 2px)) rotate(-135deg);
      }
    }
  }
}


@media(max-width: 1360px) {
  .page-receipt__photo {
    width: 40%;
  }

  .page-receipt__list ul {
    width: 50%;
    grid-template-columns: 1fr;
  }
}

@media(max-width: 860px) {
  .page-receipt__top {
    display: block;
  }

  .page-receipt__photo {
    width: 100%;
  }

  .page-receipt__list ul {
    width: 100%;
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}